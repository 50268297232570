import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

const Result = () => {
  return (
    <p className="success-message">
      Sua mensagem foi enviada com sucesso. Iremos retornar o mais breve
      possível.
    </p>
  );
};

function ContactForm({ props }) {
  const [result, showresult] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICEID,
        process.env.REACT_APP_TEMPLATEID,
        form.current,
        process.env.REACT_APP_TEMPLATEID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className="rn-form-group">
        <input type="text" name="name" placeholder="Seu nome" required />
      </div>

      <div className="rn-form-group">
        <input type="email" name="email" placeholder="Seu e-mail" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="phone" placeholder="Telefone" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="subject" placeholder="Assunto" required />
      </div>

      <div className="rn-form-group">
        <textarea name="message" placeholder="Mensagem" required></textarea>
      </div>

      <div className="rn-form-group">
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="Send"
          name="submit"
          id="mc-embedded-subscribe"
        >
          Enviar
        </button>
      </div>

      <div className="rn-form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;

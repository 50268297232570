import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import HeaderThree from "../component/header/HeaderThree";
import { VideoTag } from "react-video-tag";

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    title: "Conheça a Agrocape",
    description:
      "Com 40 anos de atuação no mercado, a Agrocape destaca-se como uma das principais empresas do setor, mantendo sua posição entre as líderes da indústria",
  },
];

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Sobre" />

        {/* Start Header Area  */}
        <HeaderThree />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center"
              key={index}
              data-black-overlay="6"
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      {value.title ? (
                        <h1 className="title">{value.title}</h1>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="video-background">
                <VideoTag
                  autoPlay={`${true}`}
                  muted={`${true}`}
                  playsInline={`${true}`}
                  loop={`${true}`}
                  src={`${"/assets/images/service/video.mp4"}`}
                  poster={`${"/assets/images/bg/bg-image-24.jpg"}`}
                />
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
        {/* End Slider Area   */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div>
                    <img
                      className="w-100"
                      src="/assets/images/about/about-5.png"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">Nossa história</h2>
                      <p className="description">
                        A Agrocape foi fundada em 1984, celebrando 40 anos de
                        dedicação ao mercado agropecuário em 2023. Sob a
                        liderança do fundador, José Carlos, esta distribuidora
                        familiar conta ainda com a participação ativa de seus
                        dois filhos, Castler e Ríley. Ao longo das décadas, a
                        Agrocape solidificou sua presença no cenário de
                        distribuição, operando com destaque nos estados da Bahia
                        e Sergipe.
                      </p>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Missão</h3>
                          <p>
                            Garantir a excelência dos produtos veterinários e
                            serviços que distribuímos, visando um atendimento de
                            qualidade para nossos clientes e fornecedores.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Visão</h3>
                          <p>
                            Manter a posição competitiva, sendo a maior
                            referência no mercado veterinário.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Estatísticas</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Team Area  */}
        <div className="rn-team-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25">
                  <h2 className="title">Nossa estrutura</h2>
                  <p>
                    Contamos com um galpão de 2000 m² e uma frota de 05 carros
                    próprios, que proporciona uma entrega em 24 horas em
                    Salvador/RMS e até 72 horas no interior da Bahia
                  </p>
                </div>
              </div>
            </div>
            <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images" />
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Heading */}
        <div className="rn-team-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25">
                  <h2 className="title">Nossos parceiros</h2>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End of Heading Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default About;

// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import error404 from "./elements/error404";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import CorporateBusiness from "./home/CorporateBusiness";
import Contact from "./elements/Contact";
import About from "./elements/About";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={CorporateBusiness}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contato`}
              component={Contact}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/sobre`}
              component={About}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();

import React, { Component } from "react";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from "react-scrollspy";

class HeaderThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      isSticky: false,
    };
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    window.addEventListener("scroll", this.stickyHeader);
  }

  menuTrigger() {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  }

  CLoseMenuTrigger() {
    this.setState({
      isMenuOpen: false,
    });
  }

  stickyHeader() {
    var value = window.scrollY;
    if (value > 100) {
      this.setState({
        isSticky: true,
      });
    } else {
      this.setState({
        isSticky: false,
      });
    }
  }

  render() {
    const { logo, color = "default-color" } = this.props;
    const { isMenuOpen, isSticky } = this.state;

    let logoUrl;
    if (isMenuOpen || isSticky) {
      logoUrl = (
        <img
          src="assets/images/logo/green_agro_logo_40.png"
          alt="Agrocape green"
        />
      );
    } else {
      logoUrl = (
        <img
          src="assets/images/logo/white_agro_logo_40.png"
          alt="Agrocape white"
        />
      );
    }

    return (
      <header
        className={`header-area header-style-two header--fixed ${color} ${
          isSticky ? "sticky" : ""
        }`}
      >
        <div className={`header-wrapper ${isMenuOpen ? "menu-open" : ""}`}>
          <div className="header-left d-flex align-items-center">
            <div className="logo">
              <a href={this.props.homeLink}>{logoUrl}</a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block ml--50">
              <Scrollspy
                className="mainmenu"
                items={["Início", "Sobre", "Contato"]}
                currentClassName="is-current"
                offset={-200}
              >
                <li>
                  <a href="/">Início</a>
                </li>
                <li>
                  <a href="/sobre">Sobre</a>
                </li>
                <li>
                  <a href="/contato">Contato</a>
                </li>
              </Scrollspy>
            </nav>

            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}

            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default HeaderThree;
